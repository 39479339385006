"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarMenu = void 0;
require("./SidebarMenu.scss");
const Search_1 = require("./Search");
const menu_1 = require("@ui/menu");
const _hooks_1 = require("@hooks");
const react_router_dom_1 = require("react-router-dom");
const menuitems_1 = require("./menuitems");
const react_1 = __importStar(require("react"));
const SidebarMenu = () => {
    const location = (0, react_router_dom_1.useLocation)();
    const tagsSubmenu = (0, menuitems_1.useTagSubMenu)();
    const { isClosed } = (0, _hooks_1.useApplicationSidebar)();
    const currentOpenKeysRef = (0, react_1.useRef)([menuitems_1.impactAnalysisSubMenu.key]);
    const [selected, setSelected] = (0, react_1.useState)(['-1']);
    const [openKeys, setOpenKeys] = (0, react_1.useState)(currentOpenKeysRef.current);
    const handleOpenChange = (0, react_1.useCallback)((keys) => {
        currentOpenKeysRef.current = keys;
        setOpenKeys(keys);
    }, []);
    (0, react_1.useEffect)(() => {
        setSelected([location.pathname]);
        if (!currentOpenKeysRef.current.length) {
            handleOpenChange([menuitems_1.impactAnalysisSubMenu.key]);
        }
    }, [location.pathname, handleOpenChange]);
    const calculatedMenuItems = (0, react_1.useMemo)(() => {
        var _a, _b, _c;
        return [
            menuitems_1.dashboardMenuItem,
            Object.assign(Object.assign({}, menuitems_1.impactAnalysisSubMenu), { children: isClosed ? (_a = menuitems_1.impactAnalysisSubMenu.children) !== null && _a !== void 0 ? _a : [] : [
                    {
                        className: 'sidebar-search-entry',
                        key: 'sidebar-search',
                        label: react_1.default.createElement(Search_1.Search, null)
                    },
                    ...(_b = menuitems_1.impactAnalysisSubMenu.children) !== null && _b !== void 0 ? _b : []
                ] }),
            menuitems_1.dataSecuritySubMenu,
            menuitems_1.applicationPerformanceSubMenu,
            menuitems_1.logsSubMenu,
            Object.assign(Object.assign({}, menuitems_1.governanceSubMenu), { children: [
                    ...(_c = menuitems_1.governanceSubMenu.children) !== null && _c !== void 0 ? _c : [],
                    tagsSubmenu
                ] })
        ];
    }, [isClosed, tagsSubmenu]);
    return react_1.default.createElement(menu_1.SonarMenu, { id: 'main-menu', items: calculatedMenuItems, mode: 'inline', onOpenChange: handleOpenChange, openKeys: openKeys, selectedKeys: selected, theme: 'dark' });
};
exports.SidebarMenu = SidebarMenu;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorMenu = exports.monitorConfigs = exports.defaultConfig = void 0;
exports.getMenuItems = getMenuItems;
require("./MonitorMenu.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const text_1 = require("@ui/text");
const antd_1 = require("antd");
const form_items_1 = require("@ui/form-items");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
exports.defaultConfig = {
    name: ''
};
exports.monitorConfigs = [
    {
        active: true,
        default: {
            monitorTypeId: sonar_core_1.PulseMonitorType.NewObjectAndFieldAccess,
            name: sonar_ts_constants_1.SETTINGS_PULSE_COPY.OBJECT_AND_FIELD_ACCESS
        },
        intrinsic: true,
        max: 1,
        title: sonar_ts_constants_1.SETTINGS_PULSE_COPY.OBJECT_AND_FIELD_ACCESS,
        type: sonar_core_1.PulseMonitorType.NewObjectAndFieldAccess
    },
    {
        active: true,
        default: {
            enableOnWeekends: false,
            monitorTypeId: sonar_core_1.PulseMonitorType.Uptime,
            monitoringWindow: sonar_core_1.MonitoringWindowType.Daily,
            name: ''
        },
        intrinsic: false,
        max: 1,
        title: sonar_ts_constants_1.SETTINGS_PULSE_COPY.UPTIME,
        type: sonar_core_1.PulseMonitorType.Uptime
    },
    {
        active: true,
        default: {
            monitorTypeId: sonar_core_1.PulseMonitorType.NewUser,
            name: ''
        },
        intrinsic: false,
        max: 1,
        title: sonar_ts_constants_1.SETTINGS_PULSE_COPY.NEW_USER,
        type: sonar_core_1.PulseMonitorType.NewUser
    }
];
function getMenuItems(monitors) {
    const menuItems = exports.monitorConfigs.filter((x) => !x.intrinsic && x.active).reduce((result, config) => {
        config.disabled = monitors.filter((x) => (x === null || x === void 0 ? void 0 : x.monitorTypeId) === config.type).length >= config.max;
        return [...result, config];
    }, []);
    return menuItems;
}
const MonitorMenu = ({ fieldId, monitors }) => {
    const menuItems = (0, react_1.useMemo)(() => getMenuItems(monitors), [monitors]);
    const currentMonitor = monitors[fieldId];
    const options = (0, react_1.useMemo)(() => {
        var _a, _b, _c, _d;
        return ((currentMonitor === null || currentMonitor === void 0 ? void 0 : currentMonitor.applicationId) ||
            ((_a = exports.monitorConfigs.find((x) => (currentMonitor === null || currentMonitor === void 0 ? void 0 : currentMonitor.monitorTypeId) &&
                x.type === currentMonitor.monitorTypeId)) === null || _a === void 0 ? void 0 : _a.intrinsic) ?
            [{
                    disabled: false,
                    label: (_c = (_b = exports.monitorConfigs.find((x) => x.type === currentMonitor.monitorTypeId)) === null || _b === void 0 ? void 0 : _b.title) !== null && _c !== void 0 ? _c : '',
                    value: (_d = currentMonitor.monitorTypeId) !== null && _d !== void 0 ? _d : 1
                }] :
            menuItems.map((config) => ({
                disabled: (currentMonitor === null || currentMonitor === void 0 ? void 0 : currentMonitor.monitorTypeId) !== config.type && config.disabled,
                label: config.title,
                value: config.type
            })));
    }, [currentMonitor, menuItems]);
    return (react_1.default.createElement(antd_1.Space, { className: 'select-monitor' },
        react_1.default.createElement(text_1.SonarText, { className: 'h2' }, sonar_ts_constants_1.SETTINGS_PULSE_COPY.SELECT_MONITOR_TYPE),
        react_1.default.createElement(form_items_1.VisualPicker, { defaultValue: currentMonitor === null || currentMonitor === void 0 ? void 0 : currentMonitor.monitorTypeId, name: fieldId === undefined ? 'monitorTypeId' : [fieldId, 'monitorTypeId'], options: options })));
};
exports.MonitorMenu = MonitorMenu;
